<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('module.rekamMedik') }}</h5>
        </div>
        <div class="modal-body">
          <input
            type="text"
            class="form-control kt-hidden"
            aria-describedby="idReservasi"
            v-model="id"
          />
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="kt-menu__link-icon fa fa-heartbeat"></i>
              </span>
            </div>
            <input
              type="text"
              id="md_edit_rekam_medik"
              class="form-control"
              placeholder="No Rekam Medik"
              aria-describedby="basic-addon1"
              v-model="nomorMedicalRecord"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-clean btn-bold btn-upper btn-font-md"
            @click="clearData()"
          >{{ $t("button.close") }}</button>
          <button
            type="button"
            class="btn btn-default btn-bold btn-upper btn-font-md"
            v-on:click="submitData()"
          >{{ $t("button.save") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const ReservationRepository = RepositoryFactory.get("reservation");
const PatientRepository = RepositoryFactory.get("patient");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  data() {
    return {
      id: null,
      nomorMedicalRecord: null,
      patientData: null
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object }
  },
  methods: {
    updateData: function (reservation) {
      this.id = parseInt(reservation.id);
      this.patientData = reservation.patient;
      this.nomorMedicalRecord = reservation.patient.medicalNo;
    },
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    submitData: function () {
      var vx = this;

      var patientData = {
        id: this.patientData.id,
        medicalNo: this.nomorMedicalRecord
      };

      let instance = new SweetAlert().showConfirm({
        swalTitle: "Apakah Anda yakin?",
        swalText: "Perubahan nomor Rekam Medik akan tersimpan.",
        swalType: "info",
        onConfirmButton: function () {
          blockUI.blockModal();
          vx.updatePatient(patientData);
        },
        onCancelButton: function () {
          blockUI.unblockModal();
        }
      });
    },
    async updateReservation(payload) {
      var vx = this;

      await ReservationRepository.updateReservation(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data Rekam Medik berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan data Rekam Medik gagal disimpan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    async updatePatient(payload) {
      var vx = this;

      await PatientRepository.patchPatient(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data Rekam Medik berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          var params = {
            id: vx.id,
            nomorMedicalRecord: vx.nomorMedicalRecord
          };

          vx.updateReservation(params);
        });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.updateData(this.modalData);
    }
  }
};
</script>
